import { Routes, Route } from "react-router-dom";

import {
  // Public routes start
  Layout,
  Home,
  Login,
  Register,
  ViewProduct,
  ViewAffiliateSellerLinkProduct,
  ProductsByCategory,
  SearchProducts,
  HotProducts,
  VendorProfile,
  FlashDeal,
  EsewaPaymentSuccess,
  EsewaVendorPaymentSuccess,
  EsewaPaymentFail,
  ContactUs,
  AboutUs,
  Faq,
  CompareList,
  ForgotPassword,
  ResetPassword,
  VerifyEmail,
  Page,
  Blogs,
  Blog,
  // Public routes end

  // User routes start
  RequireUser,
  ApplyForVendorForm,
  ApplyForAffiliateSellerForm,
  UserViewCart,
  UserCartCheckout,
  UserBuynowCheckout,
  UserDashboard,
  UserProfile,
  UserOrders,
  UserReviews,
  UserChangePassword,
  // User routes end

  // Vendor routes start
  RequireVendor,
  VendorDashboard,
  VendorQr,
  VendorChangePassword,
  VendorProducts,
  VendorProductForm,
  VendorProductsReviews,
  VendorMyProfile,
  VendorMessages,
  VendorCoupons,
  VendorDeliveryAddresses,
  VendorFlashDeals,
  VendorFlashDealProducts,
  VendorDeliveryCharge,
  VendorOrders,
  VendorTheme,
  VendorSubscriptions,
  VendorBuySubscription,
  // Vendor routes end

  // Affiliate seller routes start
  RequireAffiliateSeller,
  AffiliateSellerDashboard,
  AffiliateSellerChangePassword,
  AffiliateSellerLinks,
  AffiliateSellerLinkForm,
  // Affiliate seller routes end

  // Admin routes start
  RequireAdmin,
  AdminDashboard,
  AdminDeliveryAddresses,
  AdminDeliveryCharges,
  AdminOrders,
  AdminReviews,
  AdminSubscriptionPackages,
  AdminChangePassword,
  AdminCategories,
  AdminVariants,
  AdminProducts,
  AdminProductForm,
  AdminFlashDeals,
  AdminFlashDealForm,
  AdminSlider,
  AdminUsersUsers,
  AdminUsersVendors,
  AdminVendorSubscriptionsHistory,
  AdminUsersAffiliateSellers,
  AdminUsersVendorApplications,
  AdminUsersAffiliateSellerApplications,
  AdminAffiliateSeller,
  AdminMyProfile,
  AdminAds,
  AdminExtraAds,
  AdminSiteSetting,
  AdminContactMessages,
  AdminSubscribers,
  AdminPages,
  AdminPage,
  AdminFaq,
  AdminWhyChooseUs,
  AdminTestimonials,
  AdminTeams,
  AdminBlogs,
  AdminBlogForm,
  // Admin routes end

  // Other routes start
  NotFound,
  // Other routes end
} from "./imports/Imports";

export default function Routing() {
  return (
    <Routes>
      {/* Public routes start */}
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="product/:slug" element={<ViewProduct />} />
        <Route
          path="product/:slug/:id"
          element={<ViewAffiliateSellerLinkProduct />}
        />
        <Route path="category/:slug" element={<ProductsByCategory />} />
        <Route path="search" element={<SearchProducts />} />
        <Route path="products/hot/:key" element={<HotProducts />} />
        <Route path=":slug" element={<VendorProfile />} />
        <Route path="seller/:slug" element={<VendorProfile />} />
        <Route path="flash-deal/:slug" element={<FlashDeal />} />
        <Route
          path="page/esewa-payment-success"
          element={<EsewaPaymentSuccess />}
        />
        <Route
          path="page/vendor/esewa-payment-success"
          element={<EsewaVendorPaymentSuccess />}
        />
        <Route path="page/esewa-payment-fail" element={<EsewaPaymentFail />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="faq" element={<Faq />} />
        <Route path="compare-list" element={<CompareList />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password/:token" element={<ResetPassword />} />
        <Route path="verify-email/:token" element={<VerifyEmail />} />
        <Route path="privacy-policy" element={<Page slug="privacy-policy" />} />
        <Route
          path="terms-and-conditions"
          element={<Page slug="terms-and-conditions" />}
        />
        <Route path="user-policy" element={<Page slug="user-policy" />} />
        <Route path="vendor-policy" element={<Page slug="vendor-policy" />} />
        <Route
          path="exchange-policy"
          element={<Page slug="exchange-policy" />}
        />
        <Route path="return-policy" element={<Page slug="return-policy" />} />
        <Route path="blogs" element={<Blogs />} />
        <Route path="blogs/:slug" element={<Blog />} />
      </Route>
      {/* Public routes end */}

      {/* User routes start */}
      <Route path="user" element={<RequireUser />}>
        <Route path="apply-for-vendor" element={<ApplyForVendorForm />} />
        <Route
          path="apply-for-affiliate-seller"
          element={<ApplyForAffiliateSellerForm />}
        />
        <Route path="cart" element={<UserViewCart />} />
        <Route path="cart/checkout" element={<UserCartCheckout />} />
        <Route path="buynow/checkout" element={<UserBuynowCheckout />} />
        <Route path="profile">
          <Route index element={<UserDashboard />} />
          <Route path="information" element={<UserProfile />} />
          <Route path="orders" element={<UserOrders />} />
          <Route path="reviews" element={<UserReviews />} />
          <Route path="change-password" element={<UserChangePassword />} />
        </Route>
      </Route>
      {/* User routes start */}

      {/* Vendor routes start */}
      <Route path="vendor" element={<RequireVendor />}>
        <Route index element={<VendorDashboard />} />
        <Route path="qr" element={<VendorQr />} />
        <Route path="auth/change-password" element={<VendorChangePassword />} />
        <Route path="products" element={<VendorProducts />} />
        <Route path="reviews" element={<VendorProductsReviews />} />
        <Route
          path="products/create"
          element={<VendorProductForm mode="create" />}
        />
        <Route
          path="products/:id/edit"
          element={<VendorProductForm mode="edit" />}
        />
        <Route path="profile" element={<VendorMyProfile />} />
        <Route path="messages" element={<VendorMessages />} />
        <Route path="coupons" element={<VendorCoupons />} />
        <Route
          path="delivery-addresses"
          element={<VendorDeliveryAddresses />}
        />
        <Route path="flashdeals" element={<VendorFlashDeals />} />
        <Route
          path="flashdeals/:id/products"
          element={<VendorFlashDealProducts />}
        />
        <Route path="delivery-charge" element={<VendorDeliveryCharge />} />
        <Route path="orders" element={<VendorOrders />} />
        <Route path="theme" element={<VendorTheme />} />
        <Route path="subscriptions" element={<VendorSubscriptions />} />
        <Route
          path="subscriptions/create"
          element={<VendorBuySubscription />}
        />
      </Route>
      {/* Vendor routes end */}

      {/* Affiliate seller routes start */}
      <Route path="affiliate-seller" element={<RequireAffiliateSeller />}>
        <Route index element={<AffiliateSellerDashboard />} />
        <Route
          path="auth/change-password"
          element={<AffiliateSellerChangePassword />}
        />
        <Route path="links" element={<AffiliateSellerLinks />} />
        <Route
          path="links/create"
          element={<AffiliateSellerLinkForm mode="create" />}
        />
        <Route
          path="links/:id/edit"
          element={<AffiliateSellerLinkForm mode="edit" />}
        />
      </Route>
      {/* Affiliate seller routes end */}

      {/* Admin routes start */}
      <Route path="admin" element={<RequireAdmin />}>
        <Route index element={<AdminDashboard />} />
        <Route path="delivery-addresses" element={<AdminDeliveryAddresses />} />
        <Route path="delivery-charges" element={<AdminDeliveryCharges />} />
        <Route path="orders" element={<AdminOrders />} />
        <Route path="reviews" element={<AdminReviews />} />
        <Route path="packages" element={<AdminSubscriptionPackages />} />
        <Route path="auth/change-password" element={<AdminChangePassword />} />
        <Route
          path="categories/shopping"
          element={<AdminCategories mode="shopping" />}
        />
        <Route
          path="categories/adventure"
          element={<AdminCategories mode="adventure" />}
        />
        <Route
          path="categories/hunger"
          element={<AdminCategories mode="hunger" />}
        />
        <Route
          path="variants/shopping"
          element={<AdminVariants mode="shopping" />}
        />
        <Route
          path="variants/adventure"
          element={<AdminVariants mode="adventure" />}
        />
        <Route
          path="variants/hunger"
          element={<AdminVariants mode="hunger" />}
        />
        <Route
          path="products/shopping"
          element={<AdminProducts mode="shopping" />}
        />
        <Route
          path="products/adventure"
          element={<AdminProducts mode="adventure" />}
        />
        <Route
          path="products/hunger"
          element={<AdminProducts mode="hunger" />}
        />
        <Route
          path="products/:id/edit"
          element={<AdminProductForm mode="edit" />}
        />
        <Route
          path="flashdeals/shopping"
          element={<AdminFlashDeals mode="shopping" />}
        />
        <Route
          path="flashdeals/adventure"
          element={<AdminFlashDeals mode="adventure" />}
        />
        <Route
          path="flashdeals/hunger"
          element={<AdminFlashDeals mode="hunger" />}
        />
        <Route
          path="flashdeals/:type/create"
          element={<AdminFlashDealForm mode="create" />}
        />
        <Route
          path="flashdeals/:id/edit"
          element={<AdminFlashDealForm mode="edit" />}
        />
        <Route
          path="sliders/shopping"
          element={<AdminSlider type="shopping" />}
        />
        <Route path="sliders/hunger" element={<AdminSlider type="hunger" />} />
        <Route
          path="sliders/adventure"
          element={<AdminSlider type="adventure" />}
        />
        <Route path="users/users" element={<AdminUsersUsers />} />
        <Route path="users/vendors" element={<AdminUsersVendors />} />
        <Route
          path="vendors/:slug"
          element={<AdminVendorSubscriptionsHistory />}
        />
        <Route
          path="users/affiliate-sellers"
          element={<AdminUsersAffiliateSellers />}
        />
        <Route
          path="users/vendor-applications"
          element={<AdminUsersVendorApplications />}
        />
        <Route
          path="users/affiliate-seller-applications"
          element={<AdminUsersAffiliateSellerApplications />}
        />
        <Route
          path="affiliate-sellers/:slug"
          element={<AdminAffiliateSeller />}
        />
        <Route path="profile" element={<AdminMyProfile />} />
        <Route
          path="ads/shopping/home"
          element={<AdminAds type="shopping" />}
        />
        <Route path="ads/hunger/home" element={<AdminAds type="hunger" />} />
        <Route
          path="ads/adventure/home"
          element={<AdminAds type="adventure" />}
        />
        <Route
          path="ads/extra/shopping/home"
          element={<AdminExtraAds type="shopping" />}
        />
        <Route
          path="ads/extra/hunger/home"
          element={<AdminExtraAds type="hunger" />}
        />
        <Route
          path="ads/extra/adventure/home"
          element={<AdminExtraAds type="adventure" />}
        />
        <Route path="misc">
          <Route path="site-setting" element={<AdminSiteSetting />} />
          <Route path="contact-messages" element={<AdminContactMessages />} />
          <Route path="subscribers" element={<AdminSubscribers />} />
          <Route path="pages" element={<AdminPages />} />
          <Route path="pages/:slug" element={<AdminPage />} />
          <Route path="faq" element={<AdminFaq />} />
        </Route>
        <Route path="about">
          <Route path="why-choose-us" element={<AdminWhyChooseUs />} />
          <Route path="testimonials" element={<AdminTestimonials />} />
          <Route path="teams" element={<AdminTeams />} />
        </Route>
        <Route path="blogs" element={<AdminBlogs />} />
        <Route path="blogs/create" element={<AdminBlogForm mode="create" />} />
        <Route
          path="blogs/:slug/edit"
          element={<AdminBlogForm mode="edit" />}
        />
      </Route>
      {/* Admin routes end */}

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
