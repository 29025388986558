import * as React from "react";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import dayjs from "dayjs";

const COMPARE_EXPIRY_IN_HOURS = 72; // 3 days

const getCompareListFromLocalStorage = () => {
  const localStorageCompareList = localStorage.getItem("compareList");

  if (localStorageCompareList) {
    try {
      let compareList = JSON.parse(localStorageCompareList);

      if (compareList && Array.isArray(compareList)) {
        // Remove compare items older than COMPARE_EXPIRY_IN_HOURS
        compareList = compareList?.filter(
          (compareItem) =>
            compareItem?.compare_date &&
            dayjs(compareItem?.compare_date)
              .add(COMPARE_EXPIRY_IN_HOURS, "hour")
              .isAfter(dayjs())
        );
        localStorage.setItem("compareList", JSON.stringify(compareList));
        return compareList;
      }
    } catch (error) {
      return [];
    }
  }

  return [];
};

export const CompareContext = React.createContext<any>({} as any);

export default function CompareProvider({ children }) {
  const [compareList, setCompareList] = React.useState<object[]>(
    getCompareListFromLocalStorage()
  );

  const navigate = useNavigate();

  const addToCompareList = (item: any) => {
    // Check if product is already in the list
    if (
      !compareList?.find((compareItem: any) => compareItem?.id === item?.id)
    ) {
      const newCompareList = [
        {
          ...item,
          compare_date: new Date().toISOString(),
        },
        ...compareList,
      ];
      localStorage.setItem("compareList", JSON.stringify(newCompareList));

      setCompareList(newCompareList);
    }

    return notification.info({
      message: "Product Compare",
      description: (
        <p>
          Product successfully added to compare list.&nbsp;
          <span
            role="button"
            onClick={() => navigate("/compare-list")}
            className="text-blue-500 hover:underline"
          >
            Go to compare list
          </span>
        </p>
      ),
    });
  };

  const removeFromCompareList = (item: any) => {
    const newCompareList = compareList?.filter(
      (compareItem: any) => compareItem?.id !== item?.id
    );
    localStorage.setItem("compareList", JSON.stringify(newCompareList));
    setCompareList(newCompareList);
  };

  return (
    <CompareContext.Provider
      value={{
        compareList,
        addToCompareList,
        removeFromCompareList,
      }}
    >
      {children}
    </CompareContext.Provider>
  );
}
