import * as React from "react";

export const MiscContext = React.createContext<any>({} as any);

export default function MiscProvider({ children }) {
  const [messagesUnseenCount, setMessagesUnseenCount] = React.useState();
  const [notificationsUnseenCount, setNotificationsUnseenCount] =
    React.useState();

  return (
    <MiscContext.Provider
      value={{
        messagesUnseenCount,
        notificationsUnseenCount,
        setMessagesUnseenCount,
        setNotificationsUnseenCount,
      }}
    >
      {children}
    </MiscContext.Provider>
  );
}
