import * as React from "react";

// Public routes start
export const Layout = React.lazy(() => import("components/layout/Layout"));
export const Home = React.lazy(() => import("components/home/Home"));
export const Login = React.lazy(() => import("components/auth/login/Login"));
export const Register = React.lazy(
  () => import("components/auth/register/Register")
);
export const ViewProduct = React.lazy(
  () => import("components/product/viewProduct/ViewProduct")
);
export const ViewAffiliateSellerLinkProduct = React.lazy(
  () => import("components/product/viewProduct/ViewAffiliateSellerLinkProduct")
);
export const ProductsByCategory = React.lazy(
  () => import("components/shop/productsByCategory/ProductsByCategory")
);
export const SearchProducts = React.lazy(
  () => import("components/shop/searchProducts/SearchProducts")
);
export const HotProducts = React.lazy(
  () => import("components/shop/hotProducts/HotProducts")
);
export const VendorProfile = React.lazy(
  () => import("components/shop/vendorProfile/VendorProfile")
);
export const FlashDeal = React.lazy(
  () => import("components/shop/flashDeal/FlashDeal")
);
export const EsewaPaymentSuccess = React.lazy(
  () => import("components/pages/EsewaPaymentSuccess")
);
export const EsewaVendorPaymentSuccess = React.lazy(
  () => import("components/pages/vendor/EsewaPaymentSuccess")
);
export const EsewaPaymentFail = React.lazy(
  () => import("components/pages/EsewaPaymentFail")
);
export const ContactUs = React.lazy(
  () => import("components/contactUs/ContactUs")
);
export const AboutUs = React.lazy(() => import("components/aboutUs/AboutUs"));
export const CompareList = React.lazy(
  () => import("components/compareList/CompareList")
);
export const ForgotPassword = React.lazy(
  () => import("components/auth/forgotPassword/ForgotPassword")
);
export const ResetPassword = React.lazy(
  () => import("components/auth/resetPassword/ResetPassword")
);
export const VerifyEmail = React.lazy(
  () => import("components/auth/verifyEmail/VerifyEmail")
);
export const Faq = React.lazy(() => import("components/faq/Faq"));
export const Page = React.lazy(() => import("components/misc/page/Page"));
export const Blogs = React.lazy(() => import("components/shop/blogs/Blogs"));
export const Blog = React.lazy(() => import("components/shop/blogs/Blog"));
// Public routes end

// User routes start
export const RequireUser = React.lazy(
  () => import("routing/requireUser/RequireUser")
);
export const ApplyForVendorForm = React.lazy(
  () => import("components/user/applyForVendorForm/ApplyForVendorForm")
);
export const ApplyForAffiliateSellerForm = React.lazy(
  () =>
    import(
      "components/user/applyForAffiliateSellerForm/ApplyForAffiliateSellerForm"
    )
);
export const UserViewCart = React.lazy(
  () => import("components/user/cart/viewCart/ViewCart")
);
export const UserCartCheckout = React.lazy(
  () => import("components/user/cart/cartCheckout/CartCheckout")
);
export const UserBuynowCheckout = React.lazy(
  () => import("components/user/buynow/buynowCheckout/BuynowCheckout")
);
export const UserDashboard = React.lazy(
  () => import("components/user/dashboard/Dashboard")
);
export const UserProfile = React.lazy(
  () => import("components/user/dashboard/profile/Profile")
);
export const UserOrders = React.lazy(
  () => import("components/user/dashboard/orders/Orders")
);
export const UserReviews = React.lazy(
  () => import("components/user/dashboard/reviews/Reviews")
);
export const UserChangePassword = React.lazy(
  () => import("components/user/dashboard/changePassword/ChangePassword")
);
// User routes end

// Vendor routes start
export const RequireVendor = React.lazy(
  () => import("routing/requireVendor/RequireVendor")
);
export const VendorDashboard = React.lazy(
  () => import("components/vendor/dashboard/Dashboard")
);
export const VendorQr = React.lazy(
  () => import("components/vendor/qr/VendorQr")
);
export const VendorChangePassword = React.lazy(
  () => import("components/vendor/changePassword/ChangePassword")
);
export const VendorProducts = React.lazy(
  () => import("components/vendor/products/Products")
);
export const VendorProductForm = React.lazy(
  () => import("components/common/form/productForm/ProductForm")
);
export const VendorProductsReviews = React.lazy(
  () => import("components/vendor/reviews/Reviews")
);
export const VendorMyProfile = React.lazy(
  () => import("components/vendor/profile/VendorProfile")
);
export const VendorMessages = React.lazy(
  () => import("components/vendor/messages/Messages")
);
export const VendorCoupons = React.lazy(
  () => import("components/vendor/coupons/Coupons")
);
export const VendorDeliveryAddresses = React.lazy(
  () => import("components/vendor/deliveryAddresses/DeliveryAddresses")
);
export const VendorFlashDeals = React.lazy(
  () => import("components/vendor/flashDeals/FlashDeals")
);
export const VendorFlashDealProducts = React.lazy(
  () => import("components/vendor/flashDeals/FlashDealProducts")
);
export const VendorDeliveryCharge = React.lazy(
  () => import("components/vendor/deliveryCharge/DeliveryCharge")
);
export const VendorOrders = React.lazy(
  () => import("components/vendor/orders/Orders")
);
export const VendorTheme = React.lazy(
  () => import("components/vendor/theme/Theme")
);
export const VendorSubscriptions = React.lazy(
  () => import("components/vendor/subscriptions/Subscriptions")
);
export const VendorBuySubscription = React.lazy(
  () => import("components/vendor/subscriptions/BuySubscription")
);
// Vendor routes end

// Affiliate seller links start
export const RequireAffiliateSeller = React.lazy(
  () => import("routing/requireAffiliateSeller/RequireAffiliateSeller")
);
export const AffiliateSellerDashboard = React.lazy(
  () => import("components/affiliateSeller/dashboard/Dashboard")
);
export const AffiliateSellerChangePassword = React.lazy(
  () => import("components/affiliateSeller/changePassword/ChangePassword")
);
export const AffiliateSellerLinks = React.lazy(
  () => import("components/affiliateSeller/links/Links")
);
export const AffiliateSellerLinkForm = React.lazy(
  () => import("components/affiliateSeller/links/LinkForm")
);
// Affiliate seller links end

// Admin routes start
export const RequireAdmin = React.lazy(
  () => import("routing/requireAdmin/RequireAdmin")
);
export const AdminDashboard = React.lazy(
  () => import("components/admin/dashboard/Dashboard")
);
export const AdminDeliveryAddresses = React.lazy(
  () => import("components/admin/deliveryAddresses/DeliveryAddresses")
);
export const AdminDeliveryCharges = React.lazy(
  () => import("components/admin/deliveryCharges/DeliveryCharges")
);
export const AdminOrders = React.lazy(
  () => import("components/admin/orders/Orders")
);
export const AdminReviews = React.lazy(
  () => import("components/admin/reviews/Reviews")
);
export const AdminSubscriptionPackages = React.lazy(
  () => import("components/admin/subscriptionPackages/SubscriptionPackages")
);
export const AdminChangePassword = React.lazy(
  () => import("components/admin/changePassword/ChangePassword")
);
export const AdminCategories = React.lazy(
  () => import("components/admin/categories/Categories")
);
export const AdminVariants = React.lazy(
  () => import("components/admin/variants/Variants")
);
export const AdminProducts = React.lazy(
  () => import("components/admin/products/Products")
);
export const AdminProductForm = React.lazy(
  () => import("components/common/form/productForm/ProductForm")
);
export const AdminFlashDeals = React.lazy(
  () => import("components/admin/flashDeals/FlashDeals")
);
export const AdminFlashDealForm = React.lazy(
  () => import("components/admin/flashDeals/FlashDealForm")
);
export const AdminSlider = React.lazy(
  () => import("components/admin/slider/Slider")
);
export const AdminUsersUsers = React.lazy(
  () => import("components/admin/users/users/Users")
);
export const AdminUsersVendors = React.lazy(
  () => import("components/admin/users/vendors/Vendors")
);
export const AdminVendorSubscriptionsHistory = React.lazy(
  () => import("components/admin/vendors/VendorSubscriptionsHistory")
);
export const AdminUsersAffiliateSellers = React.lazy(
  () => import("components/admin/users/affiliateSellers/AffiliateSellers")
);
export const AdminUsersVendorApplications = React.lazy(
  () => import("components/admin/users/vendorApplications/VendorApplications")
);
export const AdminUsersAffiliateSellerApplications = React.lazy(
  () =>
    import(
      "components/admin/users/affiliateSellerApplications/AffiliateSellerApplications"
    )
);
export const AdminAffiliateSeller = React.lazy(
  () => import("components/admin/affiliateSellers/AffiliateSeller")
);
export const AdminMyProfile = React.lazy(
  () => import("components/admin/profile/AdminProfile")
);
export const AdminAds = React.lazy(() => import("components/admin/ads/Ads"));
export const AdminExtraAds = React.lazy(
  () => import("components/admin/extraAds/ExtraAds")
);
export const AdminSiteSetting = React.lazy(
  () => import("components/admin/misc/siteSetting/SiteSetting")
);
export const AdminContactMessages = React.lazy(
  () => import("components/admin/misc/contactMessages/ContactMessages")
);
export const AdminSubscribers = React.lazy(
  () => import("components/admin/misc/subscribers/Subscribers")
);
export const AdminPages = React.lazy(
  () => import("components/admin/misc/pages/Pages")
);
export const AdminPage = React.lazy(
  () => import("components/admin/misc/pages/page/Page")
);
export const AdminFaq = React.lazy(
  () => import("components/admin/misc/faq/Faq")
);
export const AdminWhyChooseUs = React.lazy(
  () => import("components/admin/aboutUs/whyChooseUs/WhyChooseUs")
);
export const AdminTestimonials = React.lazy(
  () => import("components/admin/aboutUs/testimonials/Testimonials")
);
export const AdminTeams = React.lazy(
  () => import("components/admin/aboutUs/teams/Teams")
);
export const AdminBlogs = React.lazy(
  () => import("components/admin/blogs/Blogs")
);
export const AdminBlogForm = React.lazy(
  () => import("components/admin/blogs/BlogForm")
);
// Admin routes end

// Other routes start
export const NotFound = React.lazy(
  () => import("components/notFound/NotFound")
);
// Other routes end
