import * as React from "react";

type modeType = "shopping" | "hunger" | "adventure";

const determineInitialMode = () => {
  const savedMode = localStorage.getItem("mode");

  if (savedMode && ["shopping", "hunger", "adventure"].includes(savedMode)) {
    return savedMode;
  }

  return "shopping";
};

export const ModeContext = React.createContext<any>({} as any);

export default function ModeProvider({ children }) {
  const [mode, setMode] = React.useState(determineInitialMode());

  const changeMode = (targetMode: modeType) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    if (targetMode === mode) {
      return;
    }

    localStorage.setItem("mode", targetMode);
    setMode(targetMode);
  };

  return (
    <ModeContext.Provider
      value={{
        mode,
        changeMode,
      }}
    >
      {children}
    </ModeContext.Provider>
  );
}
