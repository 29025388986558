import React from "react";
import ReactDOM from "react-dom/client";

import "antd/dist/antd.variable.min.css";
import "suneditor/dist/css/suneditor.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./index.scss";
import "./styles/preflight.scss";
import "./styles/main.scss";
import "./styles/antd.scss";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter } from "react-router-dom";
import AuthProvider from "context/auth/AuthContext";
import MiscProvider from "context/misc/MiscContext";
import ModeProvider from "context/mode/ModeContext";
import MessagesProvider from "context/messages/MessagesContext";
import NotificationsProvider from "context/notifications/NotificationsContext";
import WishlistProvider from "context/wishlist/WishlistContext";
import CartProvider from "context/cart/CartContext";
import CompareProvider from "context/compare/CompareContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <MiscProvider>
          <ModeProvider>
            <MessagesProvider>
              <NotificationsProvider>
                <WishlistProvider>
                  <CartProvider>
                    <CompareProvider>
                      <App />
                    </CompareProvider>
                  </CartProvider>
                </WishlistProvider>
              </NotificationsProvider>
            </MessagesProvider>
          </ModeProvider>
        </MiscProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
