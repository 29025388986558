import * as React from "react";

export const NotificationsContext = React.createContext<any>({} as any);

export default function NotificationsProvider({ children }) {
  const [isNotificationsOpen, setIsNotificationsOpen] =
    React.useState<boolean>(false);

  const openNotifications = () => {
    if (!isNotificationsOpen) {
      setIsNotificationsOpen(true);
    }
  };

  const closeNotifications = () => {
    if (isNotificationsOpen) {
      setIsNotificationsOpen(false);
    }
  };

  const toggleNotifications = () =>
    setIsNotificationsOpen(!isNotificationsOpen);

  return (
    <NotificationsContext.Provider
      value={{
        isNotificationsOpen,
        openNotifications,
        closeNotifications,
        toggleNotifications,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
}
