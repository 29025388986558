import * as React from "react";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";

import axiosInstance from "axios/axiosInstance";
import { getAccessToken } from "utils/auth";
import { handleError } from "utils/handler/error";
import { handleSuccess } from "utils/handler/success";

export const AuthContext = React.createContext<any>({} as any);

export default function AuthProvider({ children }) {
  const [loading, setLoading] = React.useState(false);
  const [user, setUser] = React.useState(null);

  const navigate = useNavigate();

  const loadUser = React.useCallback(async () => {
    setLoading(true);
    const accessToken = getAccessToken();

    try {
      if (accessToken) {
        const { data } = await axiosInstance.get("/accounts/user/");

        if (data?.data) {
          setUser(data.data);
          setLoading(false);
          return;
        }
      }
    } catch (error) {
      clearUser();
      setLoading(false);
      return;
    }

    clearUser();
    setLoading(false);
  }, []);

  const login = async (values, intendedPath = "/") => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.post("/accounts/login/", values);

      if (data?.data?.access) {
        localStorage.setItem("accessToken", data.data.access);
      }

      if (data?.data?.refresh) {
        localStorage.setItem("refreshToken", data.data.refresh);
      }

      setUser(data?.data);
      setLoading(false);

      if (data?.data?.is_register_as_vendor && !data?.data?.vendor_profile) {
        return navigate("/user/apply-for-vendor");
      }

      if (
        data?.data?.is_register_as_affiliate_seller &&
        !data?.data?.affiliate_seller_profile
      ) {
        return navigate("/user/apply-for-affiliate-seller");
      }

      if (data?.data?.is_vendor) {
        return navigate("/vendor");
      }

      navigate(intendedPath);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  const googleLogin = async (values, intendedPath = "/") => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.post("/accounts/google/", values);

      if (data?.data?.access) {
        localStorage.setItem("accessToken", data.data.access);
      }

      if (data?.data?.refresh) {
        localStorage.setItem("refreshToken", data.data.refresh);
      }

      setUser(data?.data);
      setLoading(false);

      if (data?.data?.is_vendor) {
        return navigate("/vendor");
      }

      navigate(intendedPath);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  const register = async (values) => {
    try {
      setLoading(true);

      const { data } = await axiosInstance.post("/accounts/register/", values);
      handleSuccess(data);

      setLoading(false);

      navigate("/login");
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  const clearUser = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    setUser(null);
  };

  const logout = () => {
    // App hangs on logout if clearUser called
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    setUser(null);
    notification.success({
      message: "You are logged out!",
    });
    navigate("/login");
  };

  return (
    <AuthContext.Provider
      value={{
        loading,
        user,
        loadUser,
        login,
        googleLogin,
        register,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
