import * as React from "react";

export const WishlistContext = React.createContext<any>({} as any);

export default function WishlistProvider({ children }) {
  const [isWishlistOpen, setIsWishlistOpen] = React.useState<boolean>(false);

  const openWishlist = () => {
    if (!isWishlistOpen) {
      setIsWishlistOpen(true);
    }
  };

  const closeWishlist = () => {
    if (isWishlistOpen) {
      setIsWishlistOpen(false);
    }
  };

  const toggleWishlist = () => setIsWishlistOpen(!isWishlistOpen);

  return (
    <WishlistContext.Provider
      value={{
        isWishlistOpen,
        openWishlist,
        closeWishlist,
        toggleWishlist,
      }}
    >
      {children}
    </WishlistContext.Provider>
  );
}
