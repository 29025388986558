import { notification } from "antd";

const notifyError = (error) => {
  notification.destroy();
  return notification.error({
    message: error?.response?.data?.title || "Something went wrong",
    description: error?.response?.data?.message || "",
  });
};

export const handleError = (error) => {
  if (error?.response?.status === 404 || error?.response?.status === 410) {
    return;
  }

  if (error?.response?.status === 401) {
    notifyError(error);
    return;
  }

  notifyError(error);
};

export default handleError;
