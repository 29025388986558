import * as React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ConfigProvider, notification } from "antd";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { AuthContext } from "context/auth/AuthContext";
import SuspenseLoading from "components/loading/suspenseLoading/SuspenseLoading";
import { handleError } from "utils/handler/error";
import Routing from "routing/Routing";

dayjs.extend(relativeTime);

ConfigProvider.config({
  theme: {
    primaryColor: "#262626",
  },
});

notification.config({
  duration: 1.5,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error: any) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403 ||
          error?.response?.status === 404
        ) {
          return false;
        }
        return failureCount < 1;
      },
      onError: (error) => handleError(error),
    },
    mutations: {
      onError: (error) => handleError(error),
    },
  },
});

export default function App() {
  const { loadUser } = React.useContext(AuthContext);

  React.useEffect(() => {
    loadUser();
  }, [loadUser]);

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });

  return (
    <React.Suspense fallback={<SuspenseLoading />}>
      <QueryClientProvider client={queryClient}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <Routing />
        </GoogleOAuthProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </React.Suspense>
  );
}
