import * as React from "react";

export const MessagesContext = React.createContext<any>({} as any);

export default function MessagesProvider({ children }) {
  const [isMessagesOpen, setIsMessagesOpen] = React.useState<boolean>(false);
  const [receiver, setReceiver] = React.useState(null);

  const openMessages = () => {
    if (!isMessagesOpen) {
      setIsMessagesOpen(true);
    }
  };

  const closeMessages = () => {
    if (isMessagesOpen) {
      setIsMessagesOpen(false);
    }
  };

  const toggleMessages = () => setIsMessagesOpen(!isMessagesOpen);

  return (
    <MessagesContext.Provider
      value={{
        isMessagesOpen,
        openMessages,
        closeMessages,
        toggleMessages,
        receiver,
        setReceiver,
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
}
