import * as React from "react";

export const CartContext = React.createContext<any>({} as any);

export default function CartProvider({ children }) {
  const [isCartOpen, setIsCartOpen] = React.useState<boolean>(false);

  const openCart = () => {
    if (!isCartOpen) {
      setIsCartOpen(true);
    }
  };

  const closeCart = () => {
    if (isCartOpen) {
      setIsCartOpen(false);
    }
  };

  const toggleCart = () => setIsCartOpen(!isCartOpen);

  return (
    <CartContext.Provider
      value={{
        isCartOpen,
        openCart,
        closeCart,
        toggleCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}
